import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'
import Style from '../styles/pages/contact.module.scss'

class betaParticipate extends React.Component {
  scroll = () => {
    console.log('iframe onLoad event');
    return typeof window !== 'undefined' && window.parent.scrollTo(0,0);
  }
  render() {
    const staticPrismicTexts = this.props.data.staticText.edges[0].node.data.texts;
    return (
      <Layout pageTitle={'Wakatoon+'} context={this.props.pageContext} _t={staticPrismicTexts}>
        <SEO pageTitle={'Wakatoon+'} context={this.props.pageContext} />
        <div className={`page codepage"`}>
          <section>
            <div 
              className={Style.betaIframe} 
              style={{
              margin: '0 auto'
            }}>
              <iframe 
                src="https://docs.google.com/forms/d/e/1FAIpQLSe6pZmbz9ylHDMqmcd4jFiqdGc2saNSxCHluyLIMxSADfomWA/viewform?embedded=true" 
                className={Style.betaIframe}
                height="1558" 
                frameBorder="0" 
                marginHeight="0" 
                marginWidth="0"
                id="form"
                onLoad={this.scroll}
                title={'form'}
              >Loading…</iframe>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default betaParticipate;

export const pageQuery = graphql`
  query betaPage($locale: String!) {
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;